import React from 'react'
import Container from '@material-ui/core/Container'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { makeStyles, useTheme } from '@material-ui/core'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'

const background = 'background@3x.jpg'

enum Breakpoint {
  LARGE = 'lg',
  SMALL = 'sm'
}

const icons = [
  {
    src: 'icon-agile@3x.png',
    title: 'Agile',
    body: () => (
      <>
        Seemlessly add an additional
        <br />
        Scrum team that is able to
        <br /> adapt to however you build software.
      </>
    )
  },
  {
    src: 'icon-full-stack@3x.png',
    title: 'Full Stack',
    body: () => (
      <>
        Server
        <br />
        Client
        <br />
        Web
        <br />
        Mobile
        <br />
        Databases
      </>
    )
  },
  {
    src: 'icon-versatile@3x.png',
    title: 'Versatile',
    body: () => (
      <>
        Expertise in wide number of languages,
        <br />
        platforms, and frameworks. Including:
        <br />
        <br />
        Node
        <br />
        C#
        <br />
        Java
        <br />
        React
        <br />
        Angular
      </>
    )
  }
]

function _Icons(props) {
  const theme = useTheme()
  const useStyles = makeStyles((theme) => ({}))
  const classes = useStyles()

  if (isWidthUp(Breakpoint.LARGE, props.width)) {
    return (
      <Grid container justify="space-around" alignItems="center">
        {icons.map((icon) => {
          const IconBody = icon.body
          return (
            <Grid item style={{ width: 365, height: 250, textAlign: 'center', margin: '156px 0px' }}>
              <img width={60} height={60} src={icon.src}></img>
              <Typography variant="h6">
                <b>{icon.title}</b>
              </Typography>
              <IconBody></IconBody>
            </Grid>
          )
        })}
      </Grid>
    )
  }
  return (
    <Grid container justify="space-around" alignItems="center" direction="column" style={{ margin: '100px 0px' }}>
      {icons.map((icon) => {
        const IconBody = icon.body
        return (
          <Grid item style={{ height: 250, textAlign: 'center' }}>
            <img width={60} height={60} src={icon.src}></img>
            <Typography variant="h6">
              <b>{icon.title}</b>
            </Typography>
            <IconBody></IconBody>
          </Grid>
        )
      })}
    </Grid>
  )
}

function _HeroGrid(props) {
  const theme = useTheme()
  const useStyles = makeStyles((theme) => ({
    heroGrid: {
      height: '100%',
      textAlign: 'center',
      [theme.breakpoints.up(Breakpoint.LARGE)]: {
        textAlign: 'left'
      }
    }
  }))
  const classes = useStyles()

  if (isWidthUp(Breakpoint.LARGE, props.width)) {
    return (
      <Grid className={classes.heroGrid} container justify="space-evenly" alignItems="center">
        <Grid item>
          <Paper elevation={0} square style={{ backgroundColor: 'transparent', color: '#fff', padding: 20 }}>
            <Typography variant="h3" style={{ textShadow: '0 2px 4px rgba(0, 0, 0, 0.5)' }}>
              <b>
                Software Development
                <br />
                Done Right
              </b>
            </Typography>
          </Paper>
        </Grid>
        <Grid item>
          <Paper elevation={0} style={{ backgroundColor: 'transparent', color: '#fff' }}>
            <Typography variant="h5" style={{ textShadow: '0 2px 4px rgba(0, 0, 0, 0.5)' }}>
              <b>
                Experienced developers
                <br />
                that increase velocity
                <br />
                without sacrificing quality.
              </b>
            </Typography>
            <Button 
            size="large" 
            disableElevation 
            variant="contained" 
            color="secondary"
            href="mailto:info@wbcs.ventures"
            target="_blank"
            rel="noopener noreferrer"
            style={{ borderRadius: 0, marginTop: 40, color: '#fff' }}
            >
              SAY HI
            </Button>
          </Paper>
        </Grid>
      </Grid>
    )
  }
  return (
    <Grid className={classes.heroGrid} direction="column" container justify="space-evenly" alignItems="center">
      <Grid item>
        <Paper elevation={0} square style={{ backgroundColor: 'transparent', color: '#fff', textAlign: 'center' }}>
          <Typography variant="h3" style={{ textShadow: '0 2px 4px rgba(0, 0, 0, 0.5)' }}>
            <b>
              Software Development <br />
              Done Right
            </b>
          </Typography>
        </Paper>
      </Grid>
      <Grid item>
        <Paper elevation={0} style={{ backgroundColor: 'transparent', color: '#fff', padding: 20 }}>
          <Typography variant="h6" style={{ textShadow: '0 2px 4px rgba(0, 0, 0, 0.5)' }}>
            <b>Experienced developers that increase velocity without sacrificing quality.</b>
          </Typography>
          <Button 
          size="large" 
          disableElevation 
          variant="contained" 
          color="secondary" 
          href="mailto:info@wbcs.ventures"
          target="_blank"
          rel="noopener noreferrer"
          style={{ borderRadius: 0, color: '#fff', marginTop: 10 }}
          >
            SAY HI
          </Button>
        </Paper>
      </Grid>
    </Grid>
  )
}

function App() {
  const theme = useTheme()
  const useStyles = makeStyles((theme) => ({
    grow: {
      flexGrow: 1
    },
    hero: {
      height: '696px',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      [theme.breakpoints.up(Breakpoint.LARGE)]: {
        backgroundPosition: 'left'
      }
    },
    title: {},
    footer: {
      bottom: 0,
      width: '100%',
      padding: theme.spacing(3, 2),
      marginTop: 'auto',
      color: '#fff',
      backgroundColor: '#000'
    }
  }))
  const classes = useStyles()
  const HeroGrid = withWidth()(_HeroGrid)
  const Icons = withWidth()(_Icons)

  return (
    <>
      <Container maxWidth={Breakpoint.LARGE}>
        <AppBar elevation={0} position="static">
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              <b>WBCS Solutions</b>
            </Typography>
            <div className={classes.grow} />
            <Button 
              color="secondary"
              href="mailto:info@wbcs.ventures"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b>CONTACT</b>
            </Button>
          </Toolbar>
        </AppBar>
        <Paper elevation={0} className={classes.hero} square style={{ backgroundImage: `url(${background})` }}>
          <HeroGrid />
        </Paper>
        <Paper elevation={0} square>
          <Icons />
        </Paper>
        <footer className={classes.footer}>
          <Typography color="inherit" variant="body1" align="right">
            we build cool shit
          </Typography>
          <Typography variant="body2" color="inherit" align="right">
            {'Copyright © '}
            <Link color="inherit" href="https://wbcs.solutions/">
              WBCS Solutions
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        </footer>
      </Container>
    </>
  )
}

export default App
